<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="changeTheme($vuetify.theme.dark)"
    >
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
  created() {
    this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int
  },
  methods: {
    changeTheme(event) {
      const theme = (event === false) ? { str: 'dark', int: true } : { str: 'light', int: false }
      this.$tools.setLocal('settings', { theme: theme })

      this.$vuetify.theme.dark = theme.int
    },
  },
}
</script>

<style>
</style>
